import RegistrationForm from '../components/RegistrationForm.vue';
// import RegistrationStage2 from '../components/RegistrationStage2.vue';

export default {
 el: '#registration',
 name: 'Registration',
 components: {
  RegistrationForm
 }
};
