<template lang="html">
 <form id="login-form" @submit.prevent="submitForm">
  <label :class="{ err: $v.phone.$invalid && $v.phone.$dirty }">
   <input
    type="text"
    name="LoginForm[username]"
    class="inputBase"
    id="loginform-username"
    autofocus
    placeholder="Телефон"
    v-model.trim="$v.phone.$model"
    v-mask="['+38 ### ### ## ##']"
   />
   <span class="errText" v-if="$v.phone.$invalid && $v.phone.$dirty">Введіть телефон в форматі +38 096 123 45 67</span>
  </label>
  <label :class="{ err: $v.password.$invalid && $v.password.$dirty }">
   <input
    v-model.trim="$v.password.$model"
    type="password"
    name="LoginForm[password]"
    class="inputBase"
    id="loginform-password"
    value=""
    placeholder="Пароль"
   />
   <span class="errText" v-if="$v.password.$invalid && $v.password.$dirty">Мінімальна довжина паролю 8 символів</span>
  </label>
  <label :class="{ err: isErrorResponse }">
   <input :disabled="isDisabledStageSubmitButton" type="submit" value="Вхід" class="btn-bg btn-b" id="login-button" />
   <span class="errText" v-if="isErrorResponse">{{ errorText }}</span>
  </label>
 </form>
</template>

<script>
import axios from 'axios';
import { required, minLength } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import { isPhoneNumber } from '../validations';

export default {
 name: 'Login',
 directives: { mask },
 data() {
  return {
   phone: '',
   password: '',
   isErrorResponse: false,
   errorText: '',
   isSending: false
  };
 },
 validations: {
  password: {
   required,
   minLength: minLength(8)
  },
  phone: {
   required,
   isPhoneNumber
  }
 },
 computed: {
  isDisabledStageSubmitButton() {
   return (
    (this.$v.phone.$invalid && this.$v.phone.$dirty) ||
    this.phone === '' ||
    (this.$v.password.$invalid && this.$v.password.$dirty) ||
    this.password === ''
   );
  }
 },
 methods: {
  submitForm() {
   const csrf = yii.getCsrfToken();

   const data = new FormData();
   data.append('LoginForm[username]', this.phone.split(' ').join('').slice(1));
   data.append('LoginForm[password]', this.password);
   data.append('_csrf', csrf);
   this.isSending = true;
   axios
    .post('/site/login', data, {
     headers: {
      'X-CSRF-Token': csrf,
      'X-Requested-With': 'XMLHttpRequest'
     }
    })
    .then((response) => {
     if (response.data.success) {
      this.isErrorResponse = false;
      location.assign('/site/index');
     } else {
      this.isErrorResponse = true;
      this.errorText = response.data.msg;
     }
     this.isSending = false;
    });
  }
 }
};
</script>
