<template lang="html">
 <div v-if="isStage1">
  <form class="reg1" @submit.prevent="submitForm">
   <label :class="{ err: isFioInvalid }">
    <input type="text" name="SignupForm[fio]" class="inputBase" v-model.trim="$v.fio.$model" placeholder="ФИО" />
    <span class="errText" v-if="(!$v.fio.isCirilic || !$v.fio.isNotSpecSymbols || !$v.fio.required) && $v.fio.$dirty">Введіть ім’я кирилицею</span>
    <span class="errText" v-if="$v.fio.isOneWord && $v.fio.$dirty">Для реєстрації введіть ім'я та прізвище</span>
   </label>

   <label :class="{ err: $v.phone.$invalid && $v.phone.$dirty }">
    <div class="phoneCodeWr">
     <input
      type="text"
      placeholder="Телефон"
      name="SignupForm[username]"
      class="inputBase"
      v-model.trim="$v.phone.$model"
      v-mask="['+38 ### ### ## ##']"
     />
     <button type="button" name="auth" class="btn-sm btn-b" :disabled="isDisabledGetCodeButton" @click="getCode">
      Отримати<br />код
     </button>
    </div>
    <span class="errText" v-if="$v.phone.$invalid && $v.phone.$dirty">Введіть телефон в форматі +38 0ХХ ХХХ ХХ ХХ</span>
   </label>

   <label :class="{ err: $v.code.$invalid && $v.code.$dirty }">
    <input
     type="text"
     name="SignupForm[otpPassword]"
     class="inputBase"
     v-model.trim="$v.code.$model"
     placeholder="_ _ _ _"
    />
    <span class="errText" v-if="$v.code.$invalid && $v.code.$dirty">Код повинен містити 4 цифри</span>
   </label>

   <label :class="{ err: isErrorResponse }">
    <input
     type="submit"
     name="reg1"
     class="btn-md btn-b btn-ico-1"
     value="Далi"
     :disabled="isDisabledStage1SubmitButton"
    />
    <span class="errText" v-if="isErrorResponse">{{ errorText }}</span>
   </label>
  </form>
 </div>

 <div v-else>
  <form class="reg2" @submit.prevent="sendOtherData">
   <div :class="{ err: isBirthDayInvalid }">
    <small>День народження: </small>
    <div class="bDayWr">
     <input type="text" name="day" class="inputSmall" v-model.trim="$v.day.$model" placeholder="День" />
     <input type="text" name="manth" class="inputSmall" v-model.trim="$v.month.$model" placeholder="Місяць" />
     <input type="text" name="year" class="inputSmall" v-model.trim="$v.year.$model" placeholder="Рік" />
    </div>
    <span class="errText" v-if="isBirthDayInvalid">Некоректна дата народження</span>
   </div>

   <label :class="{ err: $v.email.$invalid && $v.email.$dirty }">
    <input type="text" name="email" class="inputBase" v-model.trim="$v.email.$model" placeholder="Email" />
    <span class="errText" v-if="$v.email.$invalid && $v.email.$dirty">Некоректний email</span>
   </label>

   <label :class="{ err: $v.password.$invalid && $v.password.$dirty }">
    <input type="password" name="pass" class="inputBase" v-model.trim="$v.password.$model" placeholder="Пароль" />
    <span class="errText" v-if="$v.password.$invalid && $v.password.$dirty">Мінімальна довжина паролю 8 символів</span>
   </label>

   <label :class="{ err: $v.repeatPassword.$invalid && $v.repeatPassword.$dirty }">
    <input
     type="password"
     name="pass2"
     class="inputBase"
     v-model.trim="$v.repeatPassword.$model"
     placeholder="Повторіть пароль"
    />
    <span class="errText" v-if="$v.repeatPassword.$invalid && $v.repeatPassword.$dirty">Паролі не співпадають</span>
   </label>

   <a href="/content/share-rules" target="_blanck" class="clink">Правила та опис акції</a>

   <label>
    <div class="checkWr">
     <input type="checkbox" name="ch1" class="inputCheck" v-model="isAgreeRules" />
     <span>Беручи участь в акції ви підтверджуєте факт ознайомлення з даними правилами акції та згоду з ними.</span>
    </div>
   </label>

   <label>
    <div class="checkWr fS7">
     <input type="checkbox" name="ch2" class="inputCheck" v-model="isAgreeHandleInfo" />
     <span>Я даю згоду на обробку моїх персональних даних та підтверджую їх достовірність.</span>
    </div>
   </label>

   <label :class="{ err: isErrorResponse }">
    <input type="submit" name="reg" class="btn-md btn-b" :disabled="isDisabledStage2SubmitButton" value="Зареєструватися"/>
    <span class="errText" v-if="isErrorResponse">{{ errorText }}</span>
   </label>
  </form>
 </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { isNumber, isCirilic, isPhoneNumber, isNotSpecSymbols, isOneWord } from '../validations';
import { required, minLength, maxLength, sameAs, minValue, maxValue, email } from 'vuelidate/lib/validators';
import axios from 'axios';

export default {
 name: 'Stage1',
 directives: { mask },
 data() {
  return {
   fio: '',
   phone: '',
   code: '',
   isCodeSent: false,
   isSending: false,
   csrf: yii.getCsrfToken(),
   isStage1: true,
   day: '',
   month: '',
   year: '',
   email: '',
   password: '',
   repeatPassword: '',
   isAgreeRules: false,
   isAgreeHandleInfo: false,
   isSending: false,
   isErrorResponse: false,
   errorText: '',
   isAvailableSendingPhone: true
  };
 },
 validations: {
  fio: {
   required,
   isCirilic,
   isNotSpecSymbols,
   isOneWord,
  },
  phone: {
   required,
   isPhoneNumber
  },
  code: {
   required,
   isNumber,
   minLength: minLength(4),
   maxLength: maxLength(4)
  },
  day: {
   required,
   minValue: minValue(1),
   maxValue: maxValue(31)
  },
  month: {
   required,
   minValue: minValue(1),
   maxValue: maxValue(12)
  },
  year: {
   required,
   minValue: minValue(1900),
   maxValue: maxValue(2002)
  },
  email: {
   required,
   email
  },
  password: {
   required,
   minLength: minLength(8)
  },
  repeatPassword: {
   required,
   minLength: minLength(8),
   sameAsPassword: sameAs('password')
  }
 },
 computed: {
   isFioInvalid() {
     return (!this.$v.fio.isCirilic || !this.$v.fio.isNotSpecSymbols || !this.$v.fio.required || this.$v.fio.isOneWord) && this.$v.fio.$dirty;
   },
  isDisabledGetCodeButton() {
   return (
    (this.$v.phone.$invalid && this.$v.phone.$dirty) ||
    this.phone === '' ||
    this.isSending ||
    !this.isAvailableSendingPhone
   );
  },
  isBirthDayInvalid() {
   return (
    (this.$v.day.$invalid && this.$v.day.$dirty) ||
    (this.$v.month.$dirty && this.$v.month.$invalid) ||
    (this.$v.year.$invalid && this.$v.year.$dirty)
   );
  },
  isDisabledStage1SubmitButton() {
   return (
    !this.isCodeSent ||
    (this.$v.code.$invalid && this.$v.code.$dirty) ||
    this.isSending ||
    this.fio === '' ||
    this.isFioInvalid ||
    this.code === ''
   );
  },
  isDisabledStage2SubmitButton() {
   return !this.isAgreeRules || !this.isAgreeHandleInfo || this.isInvalidOtherValues || this.isSending;
  },
  isEmptyOtherValues() {
   return (
    this.day === '' ||
    this.month === '' ||
    this.year === '' ||
    this.email === '' ||
    this.password === '' ||
    this.repeatPassword === ''
   );
  },

  isInvalidOtherValues() {
   return (
    (this.$v.day.$invalid && this.$v.day.$dirty) ||
    (this.$v.month.$invalid && this.$v.month.$dirty) ||
    (this.$v.year.$invalid && this.$v.year.$dirty) ||
    (this.$v.email.$invalid && this.$v.email.$dirty) ||
    (this.$v.password.$invalid && this.$v.password.$dirty) ||
    (this.$v.repeatPassword.$invalid && this.$v.repeatPassword.$dirty)
   );
  }
 },

 methods: {
  getCode() {
   const data = new FormData();
   data.append('phone', this.phone.split(' ').join('').slice(1));
   data.append('_csrf', this.csrf);
   this.isSending = true;
   this.isAvailableSendingPhone = false;
   axios
    .post('/site/send-otp', data, {
     headers: {
      'X-CSRF-Token': this.csrf,
      'X-Requested-With': 'XMLHttpRequest'
     }
    })
    .then((response) => {
      if(typeof response.data === 'string' || !response.data.success) {
        const errorMessage = typeof response.data === 'string' ? response.data : response.data.msg;

        this.setErrorResponse(errorMessage);
        setTimeout(() => {
          this.isAvailableSendingPhone = true;
        }, 60000);

      } else if (response.data.success) {
        this.isAvailableSendingPhone = true;
        this.clearErrorResponse();
        this.$v.$reset;
      }

      this.isSending = false;
      this.isCodeSent = true;
    });
  },

  submitForm() {
   const data = new FormData();
   data.append('phone', this.phone.split(' ').join('').slice(1));
   data.append('otppass', this.code);
   data.append('fio', this.fio);
   data.append('_csrf', this.csrf);
   this.isSending = true;

   axios
    .post('/site/validate-otp', data, {
     headers: {
      'X-CSRF-Token': this.csrf,
      'X-Requested-With': 'XMLHttpRequest'
     }
    })
    .then((response) => {
     if (response.data.success) {
      this.clearErrorResponse();
      this.isStage1 = false;
      this.hideActionInfo();
     } else {
      this.setErrorResponse(response.data.msg);
     }
     this.isSending = false;
    });
  },

  setErrorResponse(message) {
   this.isErrorResponse = true;
   this.errorText = message;
  },

  clearErrorResponse() {
   this.isErrorResponse = false;
   this.errorText = '';
  },

  sendOtherData() {
   const data = new FormData();

   data.append('SignupForm[username]', this.phone.split(' ').join('').slice(1));
   data.append('SignupForm[otpPassword]', this.code);
   data.append('SignupForm[fio]', this.fio);
   data.append('SignupForm[birthDay]', this.day);
   data.append('SignupForm[birthMonth]', this.month);
   data.append('SignupForm[birthYear]', this.year);
   data.append('SignupForm[email]', this.email);
   data.append('SignupForm[password]', this.password);
   data.append('SignupForm[checkPassword]', this.repeatPassword);
   data.append('SignupForm[checkRules]', this.isAgreeRules);
   data.append('SignupForm[checkPdn]', this.isAgreeHandleInfo);
   data.append('_csrf', this.csrf);

   this.isSending = true;

   axios
    .post('/site/signup', data, {
     headers: {
      'X-CSRF-Token': this.csrf,
      'X-Requested-With': 'XMLHttpRequest'
     }
    })
    .then((response) => {
     if (response.data.success) {
      this.clearErrorResponse();
      location.assign('/site/index');
     } else {
      this.setErrorResponse(response.data.msg);
     }
     this.isSending = false;
    });
  },
  hideActionInfo() {
   const infoElem = document.querySelector('.infojs');
   infoElem.style.display = 'none';
  }
 }
};
</script>
