<template lang="html">
 <form @submit.prevent>
  <label :class="[{ err: isError }, labelClasses]" for="file-input"
   >Завантажити чек
   <input type="file" name="download1" id="file-input" class="hideInput" @change="downloadFile" ref="fileInput" />
  </label>
  <p class="errText" v-if="isErrorFile">Ви можете завантажити файл(.jpeg/.jpg/.png) розміром не більше 5МБ</p>
 </form>
</template>

<script>
import axios from 'axios';

export default {
 name: 'LoadCheck',
 props: {
  labelClasses: {
   type: String,
   default: ''
  }
 },
 data() {
  return {
   isError: true,
   isErrorFile: false
  };
 },
 methods: {
  downloadFile(e) {
   const maxSize = 5242880;
   const availableFormats = ['image/png', 'image/jpeg', 'image/jpg'];

   const file = e.target.files[0];

   if (file.size <= maxSize && availableFormats.includes(file.type)) {
    this.isErrorFile = false;
    this.sendFile(file);
   } else {
    this.isErrorFile = true;
   }
  },

  sendFile(file) {
   const data = new FormData();
   const csrf = yii.getCsrfToken();
   data.append('Sale[image_filename_file]', file);
   data.append('_csrf', csrf);

   axios({
    url: '/lk/upload-sales/',
    data,
    method: 'POST',
    headers: {
     'X-CSRF-Token': csrf,
     'Content-type': 'multipart/form-data; charset=utf-8'
    }
   }).then(response => {
    window.location.assign('/lk/upload-success');
   });
  }
 }
};
</script>
