import Vue from 'vue';
import Vuelidate from 'vuelidate';

import registrationConfig from './modules/Registration';
import loginRegistration from './modules/Login';
import loadCheckConfig from './modules/LoadCheck';

document.addEventListener('DOMContentLoaded', () => {
 Vue.use(Vuelidate);

 if (document.querySelector('#registration')) {
  new Vue(registrationConfig);
 }

 if (document.querySelector('#login')) {
  new Vue(loginRegistration);
 }
 if (document.querySelector('#loadCheck')) {
  new Vue(loadCheckConfig);
 }
});
