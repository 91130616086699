export const isNumber = (value) => {
 const regExpNumber = /^\d+$/;
 return regExpNumber.test(value) || !value;
};

export const isCirilic = (value) => {
 const regExp = /^['’а-яА-ЯіІїЇєЄҐґ-\s]+$/g;
 return regExp.test(value);
};

export const isNotSpecSymbols = (value) => {
 const specSymbol = /[([).,><;^+\]*~!@#$%&?=|`]/;
 return !specSymbol.test(value);
};

export const isPhoneNumber = (value) => {
 const regExpPhone = /^\+?38\s?0\d{2}\s?\d{3}\s?\d{2}\s?\d{2}$/;
 return regExpPhone.test(value);
};

export const isOneWord = (value) => {
 const regExpPhone = /^['’а-яА-ЯіІїЇєЄҐґ-]+$/;
 return regExpPhone.test(value);
};


