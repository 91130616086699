import LoadCheck from '../components/LoadCheck.vue';

export default {
 el: '#loadCheck',
 name: 'LoadCheckWrapper',
 data: {},
 components: {
  LoadCheck
 },
 methods: {}
};
